import React from 'react';
import './Cyfra.css'
class Odpowiedz extends React.Component {
  
    render() {
      return (
        <div className={`odpowiedz-${this.props.klasa}`}>
          {this.props.tekst}
        </div>
      );
    }
  }

  export default Odpowiedz;