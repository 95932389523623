import React from 'react';
 
import ProgressBar from "@ramonak/react-progress-bar";
class Postep extends React.Component {
    
    render() {
        return(
          <ProgressBar completed={this.props.postep} width="100%" height='40px' customLabel={this.props.text} animateOnRender={true}/>
        );
    }
  }

  export default Postep;