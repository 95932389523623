import React from 'react';
import './Przycisk.css'
class Przycisk extends React.Component {
  
    aktywuj = () =>{
        this.props.akcja(this.props.typGry,this.props.tekst);
    };
    render() {
      return (
        <div >
          <button className={`przycisk przycisk_${this.props.kolor}`} onClick={this.aktywuj}>{this.props.tekst}</button>
        </div>
      );
    }
  }

  export default Przycisk;