import "./App.css";
import React from "react";
import Cyfra from "./components/Cyfra";
import Wynik from "./components/Wynik";
import Dzialanie from "./components/Dzialanie";
import Odpowiedz from "./components/Odpowiedz";
import Przycisk from "./components/Przycisk";
import Postep from "./components/Postep";
import KrolMatematyki from "./motors/gamev1";
import InstallPrompt from "./components/InstallPrompt"
import Nagroda from "./components/Nagroda";
import audiook from "./sounds/ok1.wav";
import audiowrong from "./sounds/wrong.mp3";
import audioend from "./sounds/end.mp3";
const version = require("../package.json");
class App extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      l1: "",
      l2: "",
      d: "",
      odpowiedz: "",
      jakaodpowiedz: "ukryj",
      wyborGryKlasa: "",
      kontenerKlasa: "ukryj",
      wynikGryKlasa: "ukryj",
      zapis: 0,
      postepGry: 0,
      bledy: 0,
      prawidlowe: 0,
      numerPytania: 0,
      postepPytan: 1,
      zadania: [],
      czasStart: 0,
      czasStop: 0,
      nazwaGry: "",
      idGry: crypto.randomUUID(),
    };
  }

  componentDidMount() {
    this.setState({
      l1: "1",
      l2: "3",
      d: "+",
    });
  }

  resetuj = () => {
    this.setState({
      l1: "",
      l2: "",
      d: "",
      odpowiedz: "",
      jakaodpowiedz: "ukryj",
      wyborGryKlasa: "",
      kontenerKlasa: "ukryj",
      wynikGryKlasa: "ukryj",
      postepGry: 0,
      bledy: 0,
      prawidlowe: 0,
      numerPytania: 0,
      postepPytan: 1,
      zadania: [],
      czasStart: 0,
      czasStop: 0,
      nazwaGry: "",
      idGry: crypto.randomUUID(),
    });
  };

  aktualizuj = () => {
    this.setState({
      l1: "5",
      l2: "6",
      d: "-",
    });
    console.log("Aktualizacja!");
    this.grajok();
  };

  sprawdz = (pole, wartosc) => {
    try {
      const zadanie = this.state.zadaniaGry[this.state.numerPytania];
      if (Number(wartosc) === Number(zadanie[3])) {
        this.grajok();
        this.setState(
          {
            prawidlowe: this.state.prawidlowe + 1,
            postepGry: this.state.postepGry + 100 / this.state.zadaniaGry.length,
            jakaodpowiedz: "dobrze",
            odpowiedz: `Brawo! ${this.state.l1}${this.state.d}${this.state.l2} = ${wartosc}!`,
            numerPytania: this.state.numerPytania + 1,
            postepPytan: this.state.postepPytan + 1,
            czasStop: new Date().getTime(),
          },
          () => {
            this.wyswietlPytanie();
          }
        );
      } else {
        this.granjzle();
        this.setState({
          bledy: this.state.bledy + 1,
          jakaodpowiedz: "blad",
          czasStop: new Date().getTime(),
          odpowiedz: `${this.state.l1}${this.state.d}${this.state.l2} to nie jest ${wartosc}!`,
        });
      }
    } catch (error) {
      console.log(`Król Matematyki - błąd: ${error}`);
    }

    pole.setState({ wynik: "" }); // reset pola wpisu wyniku
  };

  grajok = () => {
    const s = new Audio(audiook);
    s.loop = false;
    s.play();
  };
  granjzle = () => {
    const s = new Audio(audiowrong);
    s.loop = false;
    s.play();
  };
  granjend = () => {
    const s = new Audio(audioend);
    s.loop = false;
    s.play();
  };

  wybierzGre = (typGry, nazwaGry) => {
    //losuję pytania
    let gra = new KrolMatematyki();
    var zadania = [];
    if (typGry === 1) {
      // dodawanie do 10
      zadania = gra.losujLiczby(20, 0, 10, "+");
    } else if (typGry === 2) {
      // odejmowanie do 10
      zadania = gra.losujLiczby(20, 0, 10, "-");
    } else if (typGry === 3) {
      zadania = gra.losujLiczby(20, 0, 10, "+-");
    } else if (typGry === 4) {
      zadania = gra.losujLiczby(20, 0, 20, "+");
    } else if (typGry === 5) {
      zadania = gra.losujLiczby(20, 0, 20, "-");
    } else if (typGry === 6) {
      zadania = gra.losujLiczby(20, 0, 20, "+-");
    } else if (typGry === 7) {
      zadania = gra.losujLiczby(20, 0, 10, "*");
    } else if (typGry === 8) {
      zadania = gra.losujLiczby(20, 0, 10, "/");
    } else if (typGry === 9) {
      zadania = gra.losujLiczby(20, 0, 10, "*/");
    } else if (typGry === 10) {
      zadania = gra.losujLiczby(20, 0, 5, "*C");
    }

    // resetuję układ gry
    this.setState(
      {
        typGry: typGry,
        wyborGryKlasa: "ukryj",
        kontenerKlasa: "",
        zadaniaGry: zadania,
        postepGry: 100 / zadania.length,
        bledy: 0,
        prawidlowe: 0,
        numerPytania: 0,
        postepPytan: 1,
        ilePytan: zadania.length,
        czasStart: new Date().getTime(),
        nazwaGry: nazwaGry,
        zapis: 0,
      },
      () => {
        this.wyswietlPytanie();
      }
    );
  };

  wyswietlPytanie() {
    try {
      const zadanie = this.state.zadaniaGry[this.state.numerPytania];
      if (this.state.numerPytania >= this.state.ilePytan) {
        this.setState(
          {
            wyborGryKlasa: "ukryj",
            kontenerKlasa: "ukryj",
            wynikGryKlasa: "",
            zapis: 1,
          },
          () => {
            this.granjend();
          }
        );
      } else {
        this.setState({
          l1: zadanie[0],
          l2: zadanie[1],
          d: zadanie[2],
          prawidlowyWynik: zadanie[3],
        });
      }
    } catch (error) {
      console.log(`Król Matematyki - błąd: ${error}`);
    }
  }

  render() {
    return (
      <div className="App">
        <div className="App-header">
          <div id="installprompt"><InstallPrompt/></div>
          <div id="logo"></div>
          <div className={this.state.wyborGryKlasa}>
            <h2>Wybierz grę:</h2>
            <Przycisk akcja={this.wybierzGre} typGry={1} tekst="Dodawanie do 10" kolor={0} />
            <Przycisk akcja={this.wybierzGre} typGry={2} tekst="Odejmowanie do 10" kolor={0} />
            <Przycisk akcja={this.wybierzGre} typGry={3} tekst="Dodawanie i odejmowanie do 10" kolor={0} />
            <Przycisk akcja={this.wybierzGre} typGry={4} tekst="Dodawanie do 20" kolor={1} />
            <Przycisk akcja={this.wybierzGre} typGry={5} tekst="Odejmowanie do 20" kolor={1} />
            <Przycisk akcja={this.wybierzGre} typGry={6} tekst="Dodawanie i odejmowanie do 20" kolor={1} />
            <Przycisk akcja={this.wybierzGre} typGry={10} tekst="Mnożenie do 5x10" kolor={2} />
            <Przycisk akcja={this.wybierzGre} typGry={7} tekst="Mnożenie do 100" kolor={2} />
            <Przycisk akcja={this.wybierzGre} typGry={8} tekst="Dzielenie do 100" kolor={2} />
            <Przycisk akcja={this.wybierzGre} typGry={9} tekst="Mnożenie i dzielenie do 100" kolor={2} />
          </div>

          <div className={this.state.kontenerKlasa}>
            <div className="App-container">
              <Cyfra wskazuj={this.state.l1} />
              <Dzialanie wskazuj={this.state.d} />
              <Cyfra wskazuj={this.state.l2} />
              <Cyfra wskazuj="=" />
              <Wynik akcja={this.sprawdz} />
            </div>
            <Odpowiedz tekst={this.state.odpowiedz} klasa={this.state.jakaodpowiedz} />
            <Postep postep={this.state.postepGry} text={`${this.state.postepPytan}/${this.state.ilePytan}`} />
          </div>
          <div className={this.state.wynikGryKlasa}>
            <Nagroda
              idGry={this.state.idGry}
              nazwaGry={this.state.nazwaGry}
              prawidlowe={this.state.prawidlowe}
              bledy={this.state.bledy}
              czasStart={this.state.czasStart}
              czasStop={this.state.czasStop}
              ilePytan={this.state.ilePytan}
              akcja={this.resetuj}
              zapis={this.state.zapis}
            />
          </div>
          <h3>&copy; 2022-2023 M. Chuć :) Król Matematyki v.{version.version}</h3>
          <h4>
            Aplikacja używa cookies, aby zapisywać i analizować udzielone odpowiedzi, a także aby realizować śledzenie zaangażowania w
            aplikację, celem usprawnienia jej działania. Użyto dźwięków dostępnych w serwisie freesound.org.{" "}
            <a href="https://www.flaticon.com/free-icons/crown" title="crown icons">
              Ikona korony utworzona przez Pixel perfect z Flaticon.com
            </a>
          </h4>
        </div>
      </div>
    );
  }
}

export default App;
