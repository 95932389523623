import React from 'react';
import './Cyfra.css'
class Wynik extends React.Component {
    constructor(props){
        super(props);
        this.state = {wynik:''};
    };
    ogranicz = (evt) =>
    {
        if (evt.target.value.length > 4) {
            evt.target.value = evt.target.value.slice(0,4); 
        }
    }
    zmiana = (evt)=> {
        const v = evt.target.value;  
        this.setState({wynik:v});
    };

    wpiszenter = (evt) =>
    {
        if (evt.key === 'Enter') {
            this.props.akcja(this, evt.target.value); //zmienna przekazana do uruchomienia ze strony rodzica, kiedy WCIŚNIĘTO ENTER
          }
        
    }

    render() {
      return (
        <div>
          <input autoFocus className='wynik' type="number" id="wynik" pattern="[0-9]{1,2}" onInput={this.ogranicz} step={1} onChange={this.zmiana} onKeyDown={this.wpiszenter} value={this.state.wynik} placeholder="wpisz wynik..."></input>
        </div>
      );
    };
  }

  export default Wynik;