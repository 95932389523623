/**
 * (C) Marcin P. Chuć
 * marcin pracuje w AFYA.pl ... i nie tylko :)
 */
class KMStorage {
  /**
   *
   * @param {*} nazwaGry
   * @param {*} czasGry
   * @param {*} ilePytan
   * @param {*} ilePrawidlowych
   * @param {*} ileBlednych
   * @param {*} ileKoron
   * @param {*} idGry
   */
  zapiszWynik(nazwaGry, czasGry, ilePytan, ilePrawidlowych, ileBlednych, ileKoron, idGry = "-") {
    let magazyn_ = window.localStorage.getItem("krolMatematyki") || '{"dane":[]}';
    let magazyn = JSON.parse(magazyn_);
    let dane = {
      dataWpisu: new Date().getTime(),
      nazwaGry: nazwaGry,
      czasGry: czasGry,
      ilePytan: ilePytan,
      ilePrawidlowych: ilePrawidlowych,
      ileBlednych: ileBlednych,
      ileKoron: ileKoron,
      idGry: idGry,
    };
    if (!("dane" in magazyn)) {
      magazyn["dane"] = [];
    }

    for (let zapisy in magazyn["dane"]) { //sprawdzam, czy nie ma 2x zapisu w tej samej chwili
      if (magazyn["dane"][zapisy]["idGry"] === idGry) {
        return false;
      }
    }

    magazyn["dane"].push(dane);
    window.localStorage.setItem("krolMatematyki", JSON.stringify(magazyn));
  }
}
export default KMStorage;
