import React from 'react';
import './Cyfra.css'
class Dzialanie extends React.Component {
  
    render() {
      return (
        <div className='dzialanie'>
          {this.props.wskazuj}
        </div>
      );
    }
  }

  export default Dzialanie;