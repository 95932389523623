import React from "react";
import "./Nagroda.css";
import KMStorage from "../motors/storagev1";
class Nagroda extends React.Component {
  render() {
    const ilePytan = this.props.ilePytan;
    const bledy = this.props.bledy;
    const czasStart = this.props.czasStart;
    const czasStop = this.props.czasStop;

    const czasGry = Math.round((czasStop - czasStart) / 1000);
    let minuty = Math.floor((czasGry / 60))
    let sekundy = Math.floor((czasGry - (minuty * 60)) % 60);
    const czasGryslownie = `${minuty} min. ${sekundy} sek.`;

    let korony = 5;
    let maxKorony = 5;
    let minKorony = 1;
    let nazwaKorony;
    if (bledy > 0) {
      if (bledy > ilePytan / 2) {
        korony = minKorony;
      } else {
        let ileOdjac = Math.round((bledy * maxKorony) / (ilePytan / 2));
        if (ileOdjac === 0) {
          ileOdjac = 1;
        } else if (ileOdjac >= maxKorony) {
          ileOdjac = maxKorony - 1;
        }
        korony = maxKorony - ileOdjac;
      }
    }

    if (korony === 1) {
      nazwaKorony = "1 kronę";
    } else if (korony >= 2 && korony <= 4) {
      nazwaKorony = `${korony} korony`;
    } else if (korony === 5) {
      nazwaKorony = "5 koron";
    }

    if (this.props.zapis===1)
    {
      let store = new KMStorage()
      store.zapiszWynik(this.props.nazwaGry,czasGry,this.props.ilePytan,this.props.prawidlowe,this.props.bledy,korony,this.props.idGry);
    }

    return (
      <div className="nagroda">
        <div className="nagroda_nazwa">{this.props.nazwaGry}</div>
        <div className="break"></div>
        <div className="nagroda_wyniki">
          <div className="nagroda_prawidlowe">
            <div className="nagroda_header">Odpowiedzi prawidłowe:</div>
            <div className="nagroda_body zielone">{this.props.prawidlowe}</div>
          </div>
          <div className="nagroda_bledne">
            <div className="nagroda_header">Odpowiedzi błędne:</div>
            <div className="nagroda_body czerwone">{this.props.bledy}</div>
          </div>
        </div>
        <div className="nagroda_korony">
          <div className="nagroda_header">Twój czas:</div>
          <div>{czasGryslownie}</div>
          <div className="nagroda_header">Zdobywasz {nazwaKorony}</div>
          <div className={`nagroda_twoje_korony nagroda_korony_${korony}`}></div>
        </div>
        <div className="break"></div>
        <div className="nagroda_wyniki">
          <button className='nagroda_przycisk' onClick={this.props.akcja}>Wybierz inną grę, albo zacznij od nowa >></button>
        </div>
      </div>


    );
  }
}

export default Nagroda;
