class KrolMatematyki {
    /**
     * (C) 2022-2023 Marcin P. Chuć
     *  Marcin pracuje w AFYA.pl
     */
    constructor() {
        this.zadania = {};
    }

    losujLiczby(ile = 20, zakresMin = 0, zakresMax = 10, operator = "+") {
        /***
         *  zwraca wynik w postaci listy tablic:
         * [liczba1, liczba2, operator, wynik poprawnej operacji]
         */
        var wynikLosowania = []; // zawiera wylosowane zestawy
        let numerLosowania = 0; // inkrement do losowania
        while (numerLosowania < ile) {
            // samo dodawanie
            if (operator === "+") {
                var wylosowane = this.losujDodawanie(zakresMin, zakresMax);
                if (this.sprawdzCzyJedyne(wynikLosowania, wylosowane)) {
                    wynikLosowania.push(wylosowane);
                    numerLosowania++;
                }
            } else if (operator === "-") {
                //samo odejmowanie
                const wylosowane = this.losujOdejmowanie(zakresMin, zakresMax);
                if (this.sprawdzCzyJedyne(wynikLosowania, wylosowane)) {
                    wynikLosowania.push(wylosowane);
                    numerLosowania++;
                }
            } else if (operator === "+-") {
                // dodawnanie i odejmowanie
                let losujOperator = this.losuj(0, 1);

                if (losujOperator === 1) {
                    wylosowane = this.losujDodawanie(zakresMin, zakresMax);
                } else {
                    wylosowane = this.losujOdejmowanie(zakresMin, zakresMax);
                }
                if (this.sprawdzCzyJedyne(wynikLosowania, wylosowane)) {
                    wynikLosowania.push(wylosowane);
                    numerLosowania++;
                }
            } else if (operator === "*") {
                const wylosowane = this.losujMnozenie(zakresMin, zakresMax);
                if (this.sprawdzCzyJedyne(wynikLosowania, wylosowane)) {
                    wynikLosowania.push(wylosowane);
                    numerLosowania++;
                }
            } else if (operator === "*C") {
                const wylosowane = this.losujMnozenieDoZakresu(zakresMin, zakresMax);
                if (this.sprawdzCzyJedyne(wynikLosowania, wylosowane)) {
                    wynikLosowania.push(wylosowane);
                    numerLosowania++;
                }
            }else if (operator === "/") {
                const wylosowane = this.losujDzielenie(zakresMin, zakresMax);
                if (this.sprawdzCzyJedyne(wynikLosowania, wylosowane)) {
                    wynikLosowania.push(wylosowane);
                    numerLosowania++;
                }
            } else if (operator === "*/") {
                // dodawnanie i odejmowanie
                let losujOperator = this.losuj(0, 1);

                if (losujOperator === 1) {
                    wylosowane = this.losujMnozenie(zakresMin, zakresMax);
                } else {
                    wylosowane = this.losujDzielenie(zakresMin, zakresMax);
                }

                if (this.sprawdzCzyJedyne(wynikLosowania, wylosowane)) {
                    wynikLosowania.push(wylosowane);
                    numerLosowania++;
                }
            }
        }


        //console.log(JSON.stringify(wynikLosowania));
        return wynikLosowania;
    };

    losuj(zakresMin, zakresMax) {
        /**
         * losuje liczbę z zakresu min-max
         */
        let liczba = zakresMin - 1; // warunek dla uruchomienia losowania
        while (liczba < zakresMin) {
            liczba = Math.round(Math.random() * zakresMax);
        }
        return liczba;
    }

    losujDodawanie(zakresMin, zakresMax) {
        let liczba1 = this.losuj(zakresMin, zakresMax);
        let liczba2 = this.losuj(0, 10 - liczba1); // suma nie może być większa niż 10!
        return [liczba1, liczba2, "+", liczba1 + liczba2, `${liczba1}${liczba2}+`];
    }

    losujOdejmowanie(zakresMin, zakresMax) {
        let liczba1 = this.losuj(zakresMin, zakresMax);
        let liczba2 = this.losuj(0, liczba1); // różnica nie może być mniejsza niż 0, więc liczba 2 nie może być większa niż liczba 1
        return [liczba1, liczba2, "-", liczba1 - liczba2, `${liczba1}${liczba2}-`];
    }

    losujMnozenie(zakresMin, zakresMax) {
        let liczba1 = this.losuj(zakresMin, zakresMax);
        let liczba2 = this.losuj(0, liczba1); // różnica nie może być mniejsza niż 0, więc liczba 2 nie może być większa niż liczba 1
        return [liczba1, liczba2, "×", liczba1 * liczba2, `${liczba1}${liczba2}*`];
    }

    losujMnozenieDoZakresu(zakresMin, zakresMax) {
        let liczba1 = this.losuj(zakresMin, zakresMax);
        let liczba2 = this.losuj(0, 10);
        let zamiana = this.losuj(0, 1);
        if (zamiana === 1) {
            let liczba3 = liczba1;
            liczba1 = liczba2;
            liczba2 = liczba3;
        }
        return [liczba1, liczba2, "×", liczba1 * liczba2, `${liczba1}${liczba2}*`];
    }

    losujDzielenie(zakresMin, zakresMax) {
        let zakresMin1 = 1;
        if (zakresMin > 0) {
            zakresMin1 = zakresMin;
        }  // dzieci i większość dorosłych nie umie dzielić przez 0...
        let liczba1 = this.losuj(zakresMin1, zakresMax);
        let liczba2 = this.losuj(zakresMin, liczba1); // różnica nie może być mniejsza niż 0, więc liczba 2 nie może być większa niż liczba 1
        let wynik = liczba1 * liczba2
        return [wynik, liczba1, "÷", liczba2, `${liczba1}${liczba2}/`];
    }

    sprawdzCzyJedyne(zbior, co) {
        for (var i = 0; i < zbior.length; i++) {
            const dana = zbior[i][4];
            if (dana === co[4]) {
                return false;
            }
        }
        return true;

    }


}

export default KrolMatematyki;
