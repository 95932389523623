import React from "react";
class InstallPrompt extends React.Component {
  constructor(props) {
    super(props);
    this.state = { pytanie: null };
  }

  componentDidMount() {
    console.log("Instalacja PWA");
    if ("serviceWorker" in navigator) {
      window.addEventListener("load", function () {
        navigator.serviceWorker
          .register("/serviceWorker.js")
          .then(res => console.log("service worker registered"))
          .catch(err => console.log("service worker not registered", err));
      });

      window.addEventListener("beforeinstallprompt", e => {
        e.preventDefault();
        this.setState({ pytanie: e });
        this.instalujPrompt = e;
        this.zainstalujMniePrompt();
      });
    }
  }

  zainstalujMniePrompt() {
    console.log("Ukazuję przycisk ");
    document.getElementById("installprompt").style.display = "block";
  }

  async zainstalujMnieDo() {
    console.log("Klik");
    this.state.pytanie.prompt();
    const { wynik } = await this.state.pytanie.userChoice;
    this.setState({ pytanie: null });
    if (wynik === "accepted") {
      console.log("User accepted the install prompt.");
    } else if (wynik === "dismissed") {
      console.log("User dismissed the install prompt");
    }
    document.getElementById("installprompt").style.display = "none";
  }

  render() {
    return (
      <button id="zainstalujBtn" onClick={async () => {await this.zainstalujMnieDo();}}>
        Dodaj skrót do aplikacji
      </button>
    );
  }
}
export default InstallPrompt;
